import { getGlobalWindow } from "./load-intercom-widget";

const globalWindow = getGlobalWindow();

const throwIntercomScriptError = () => {
    throw new Error("Intercom script is not available");
};

export const bootIntercomForAuthenticatedUsers = (uuid: string, intercomHash: string) => {
    if (!globalWindow) {
        return;
    }

    if (globalWindow.Intercom === undefined) {
        throwIntercomScriptError();
    }

    globalWindow.Intercom("boot", {
        api_base: process.env.NEXT_PUBLIC_INTERCOM_APP_BASE_URL as string,
        app_id: process.env.NEXT_PUBLIC_INTERCOM_ID as string,
        user_id: uuid,
        user_hash: intercomHash,
    });
};

export const bootIntercomForUnAuthenticatedUsers = () => {
    if (!globalWindow) {
        return;
    }

    if (globalWindow.Intercom === undefined) {
        throwIntercomScriptError();
    }

    globalWindow.Intercom("boot", {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_ID as string,
    });
};

export const shutdownIntercom = () => {
    if (!globalWindow) {
        return;
    }

    if (globalWindow.Intercom === undefined) {
        throwIntercomScriptError();
    }

    globalWindow.Intercom("shutdown");
};
