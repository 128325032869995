/* eslint-disable @typescript-eslint/no-explicit-any */
interface IWindow {
    intercomSettings: Record<string, string>;
    Intercom: (event: string, eventData?: Record<string, string>) => void;
    attachEvent: (event: string, cb: () => void) => void;
}

export const getGlobalWindow = () => {
    if (typeof window !== "undefined") {
        return window as unknown as IWindow & Window & typeof globalThis;
    }
    return null;
};

export const loadIntercomWidget = () => {
    const globalWindow = getGlobalWindow();

    if (!globalWindow) {
        return;
    }

    const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_ID as string;

    globalWindow.intercomSettings = {
        app_id: intercomAppId,
    };

    const ic = globalWindow.Intercom;

    if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", globalWindow.intercomSettings);
    } else {
        const d = document;

        const i = function () {
            // eslint-disable-next-line prefer-rest-params
            (i as any).c(arguments);
        };

        (i as any).q = [];

        (i as any).c = (args: any) => {
            (i as any).q.push(args);
        };

        globalWindow.Intercom = i;

        const l = () => {
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${intercomAppId}`;
            const x = d.getElementsByTagName("script")[0];

            if (x && x.parentNode) {
                x.parentNode.insertBefore(s, x);
            }
        };

        if (globalWindow.attachEvent) {
            globalWindow.attachEvent("onload", l);
        } else {
            globalWindow.addEventListener("load", l, false);
        }
    }
};
