// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_TRACES_SAMPLE_RATE = parseFloat(
    process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE || 0.0
);
const SENTRY_ENVIRONMENT =
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "development";
const SENTRY_TRACING_ORIGIN =
    process.env.NEXT_PUBLIC_SENTRY_TRACING_ORIGIN ||
    /.+futurewayhome\.co\.uk.+/;

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        integrations: [
            new Sentry.BrowserTracing({
                tracingOrigins: [SENTRY_TRACING_ORIGIN],
            }),
        ],
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
