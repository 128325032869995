export const menuData = (logOut: () => void, uuid: string | null, isFirstFund: boolean) => {
    return [
        {
            id: "eligibility",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/eligibility`,
            title: "Eligibility",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: !isFirstFund,
                    subMenuParentId: isFirstFund ? "more" : "",
                },
            },
        },
        {
            id: "application-process",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/application-process`,
            title: "Application process",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: isFirstFund,
                },
                medium: {
                    isInMainMenu: isFirstFund,
                },
            },
        },
        {
            id: "where-we-buy",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/where-we-buy`,
            title: "Where we buy",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: !isFirstFund,
                    subMenuParentId: isFirstFund ? "more" : "",
                },
            },
        },
        {
            id: "more",
            title: "More",
            url: "",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            hasSubMenu: true,
            hasRightSeparator: true,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: true,
                },
            },
        },
        {
            id: "how-it-works",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/how-it-works`,
            title: "How it works",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
            },
        },
        {
            id: "testimonials",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/testimonials`,
            title: "Testimonials",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
            },
        },
        {
            id: "intermediaries",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/intermediaries`,
            title: "Intermediaries",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
            },
        },
        {
            id: "about-us",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/about`,
            title: "About us",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
            },
        },
        {
            id: "faqs",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/faqs`,
            title: "FAQs",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
            },
        },
        {
            id: "our-blog",
            url: "https://blog.wayhome.co.uk",
            title: "Our blog",
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "more",
                },
            },
        },
        {
            id: "homes",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/suitability/matches/${uuid}`,
            title: "Homes",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            appearance: {
                small: {
                    isInMainMenu: isFirstFund,
                },
                medium: {
                    isInMainMenu: isFirstFund,
                },
            },
        },
        {
            id: "saved-homes",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/suitability/matches/${uuid}/filter/liked`,
            title: "Saved Homes",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            hasRightSeparator: true,
            appearance: {
                small: {
                    isInMainMenu: isFirstFund,
                },
                medium: {
                    isInMainMenu: isFirstFund,
                },
            },
        },
        {
            id: "my-account",
            title: "My account",
            url: "",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            hasSubMenu: true,
            appearance: {
                small: {
                    isInMainMenu: false,
                },
                medium: {
                    isInMainMenu: true,
                },
            },
        },
        {
            id: "account-settings",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/account-settings`,
            title: "Account settings",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,

            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "my-account",
                },
            },
        },
        {
            id: "contact-preferences",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/contact-preferences`,
            title: "Contact preferences",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            isExternal: true,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "my-account",
                },
            },
        },
        {
            id: "log-out",
            url: "",
            title: "Log out",
            showWhenLoggedIn: true,
            showWhenLoggedOut: false,
            handleClick: logOut,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: false,
                    subMenuParentId: "my-account",
                },
            },
        },
        {
            id: "log-in",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/login`,
            title: "Log in",
            showWhenLoggedIn: false,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: true,
                },
            },
        },
        {
            id: "sign-up",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/suitability`,
            title: "Sign up",
            isButton: true,
            showWhenLoggedIn: false,
            showWhenLoggedOut: true,
            appearance: {
                small: {
                    isInMainMenu: true,
                },
                medium: {
                    isInMainMenu: true,
                },
            },
        },
    ];
};
