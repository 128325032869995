import { useCallback, useEffect } from "react";

import { useAuthUser } from "@wayhome-uk/hooks";

import { bootIntercomForAuthenticatedUsers, bootIntercomForUnAuthenticatedUsers, shutdownIntercom } from "./helpers";
import { withIntercom } from "./with-intercom";

export const Intercom = withIntercom(() => {
    const { user } = useAuthUser();

    const { fbUser } = user;

    const initialize = useCallback(async () => {
        if (fbUser) {
            const tokenResults = await fbUser.getIdTokenResult();
            const uuid = tokenResults.claims.wayhome_id as string;

            const intercomHash = tokenResults?.claims?.intercom_hash as string;
            bootIntercomForAuthenticatedUsers(uuid, intercomHash);
            return;
        }

        shutdownIntercom();
        bootIntercomForUnAuthenticatedUsers();
    }, [fbUser]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return null;
});
