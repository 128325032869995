import React from "react";

export const ErrorIllustration = () => (
    <svg width="191" height="254" viewBox="0 0 191 254" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="184.09" width="86.4375" height="5.13111" rx="1.45681" fill="#F2CB80" />
        <rect x="47.0142" y="183.357" width="4.89731" height="22.9678" fill="#C4C4C4" />
        <rect x="31.0977" y="196.551" width="8.08056" height="44.2253" rx="0.485604" fill="#EFC064" />
        <rect x="34.2988" y="199.294" width="4.12763" height="0.485604" rx="0.242802" fill="white" />
        <rect x="34.2988" y="202.694" width="4.12763" height="0.485604" rx="0.242802" fill="white" />
        <rect x="34.2988" y="206.093" width="4.12763" height="0.485604" rx="0.242802" fill="white" />
        <rect x="34.2988" y="209.492" width="4.12763" height="0.485604" rx="0.242802" fill="white" />
        <rect x="34.2988" y="212.891" width="4.12763" height="0.485604" rx="0.242802" fill="white" />
        <rect x="34.2988" y="216.291" width="4.12763" height="0.485604" rx="0.242802" fill="white" />
        <mask id="mask0_532_957" mask-type="alpha" maskUnits="userSpaceOnUse" x="31" y="196" width="9" height="45">
            <rect x="31.0977" y="196.551" width="8.08056" height="44.2253" rx="0.485604" fill="#FBEED5" />
        </mask>
        <g mask="url(#mask0_532_957)">
            <rect x="26.9351" y="192.886" width="5.14218" height="55.7092" fill="#F5D79D" />
        </g>
        <path
            d="M46.0162 204.345C46.0266 203.816 46.4583 203.393 46.9872 203.393H51.9388C52.4678 203.393 52.8994 203.816 52.9098 204.345L53.6063 239.786C53.6169 240.33 53.1791 240.777 52.6352 240.777H46.2908C45.7469 240.777 45.3091 240.33 45.3198 239.786L46.0162 204.345Z"
            fill="#913946"
        />
        <rect x="64.6445" y="200.949" width="5.14217" height="19.7914" fill="#C4C4C4" />
        <mask id="mask1_532_957" mask-type="alpha" maskUnits="userSpaceOnUse" x="60" y="190" width="14" height="14">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.91 190.687C61.8636 191.84 60.4819 194.03 60.4819 196.543C60.4819 200.253 63.4967 203.262 67.2157 203.262C70.9347 203.262 73.9495 200.253 73.9495 196.543C73.9495 194.132 72.6773 192.018 70.7663 190.832V195.687L67.3382 198.131L63.91 195.687V190.687Z"
                fill="#EAE8E0"
            />
        </mask>
        <g mask="url(#mask1_532_957)">
            <rect x="67.3384" y="186.158" width="12.733" height="21.5018" fill="#D5D3CC" />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.3382 198.131L63.9101 195.687V190.687C61.8636 191.84 60.4819 194.03 60.4819 196.543C60.4819 200.253 63.4968 203.262 67.2157 203.262C67.2566 203.262 67.2975 203.261 67.3382 203.261V198.131Z"
            fill="#C4C4C4"
        />
        <path
            d="M19.4208 203.784C19.561 202.608 21.2665 202.608 21.4067 203.784L21.7278 206.476H19.0996L19.4208 203.784Z"
            fill="#EDC5BF"
        />
        <mask id="mask2_532_957" mask-type="alpha" maskUnits="userSpaceOnUse" x="19" y="197" width="3" height="10">
            <path
                d="M19.4208 203.653C19.561 202.477 21.2665 202.477 21.4067 203.653L21.7278 206.344H19.0996L19.4208 203.653Z"
                fill="#EDC5BF"
            />
        </mask>
        <g mask="url(#mask2_532_957)">
            <rect x="19.8882" y="197.034" width="1.05129" height="1.57354" fill="#4D483F" />
        </g>
        <path
            d="M19.0996 203.683C19.0996 203.415 19.317 203.197 19.5852 203.197H21.2422C21.5104 203.197 21.7278 203.415 21.7278 203.683V235.649C21.7278 235.917 21.5104 236.134 21.2422 236.134H19.5852C19.317 236.134 19.0996 235.917 19.0996 235.649V203.683Z"
            fill="#A29ECA"
        />
        <path
            d="M23.5834 206.471C23.7236 205.296 25.429 205.296 25.5693 206.471L25.8904 209.163H23.2622L23.5834 206.471Z"
            fill="#EDC5BF"
        />
        <mask id="mask3_532_957" mask-type="alpha" maskUnits="userSpaceOnUse" x="23" y="199" width="3" height="11">
            <path
                d="M23.5834 206.34C23.7236 205.165 25.429 205.165 25.5693 206.34L25.8904 209.032H23.2622L23.5834 206.34Z"
                fill="#EDC5BF"
            />
        </mask>
        <g mask="url(#mask3_532_957)">
            <rect x="24.0508" y="199.722" width="1.05129" height="1.57354" fill="#4D483F" />
        </g>
        <path
            d="M23.2622 206.371C23.2622 206.102 23.4796 205.885 23.7478 205.885H25.4048C25.673 205.885 25.8904 206.102 25.8904 206.371V238.336C25.8904 238.605 25.673 238.822 25.4048 238.822H23.7478C23.4796 238.822 23.2622 238.605 23.2622 238.336V206.371Z"
            fill="#A1C2C6"
        />
        <rect x="13.689" y="194.634" width="1.51583" height="12.7514" fill="#C4C4C4" />
        <rect x="12.0215" y="206.93" width="5.00225" height="1.66982" rx="0.485604" fill="#012C31" />
        <rect x="11.2637" y="208.144" width="6.3665" height="18.2162" rx="0.971208" fill="#E17C2B" />
        <path
            d="M13.0825 191.658C13.0825 191.122 13.5173 190.687 14.0537 190.687H14.8398C15.3762 190.687 15.811 191.122 15.811 191.658V192.689C15.811 193.324 15.7139 193.955 15.5229 194.561V194.561C15.4237 194.876 15.132 195.089 14.8022 195.089H14.0913C13.7615 195.089 13.4698 194.876 13.3707 194.561V194.561C13.1797 193.955 13.0825 193.324 13.0825 192.689V191.658Z"
            fill="#C4C4C4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.4266 181.158C80.6221 181.158 79.9698 181.81 79.9698 182.615V212.189H6.46755V182.615C6.46755 181.81 5.81531 181.158 5.01073 181.158H3.66043C2.85585 181.158 2.20361 181.81 2.20361 182.615V222.949L2.20371 222.966V240.783C2.20371 241.319 2.63853 241.754 3.17492 241.754H83.2624C83.7988 241.754 84.2337 241.319 84.2337 240.783V222.968C84.2337 222.961 84.2338 222.955 84.2338 222.949V182.615C84.2338 181.81 83.5815 181.158 82.7769 181.158H81.4266Z"
            fill="#4E8C93"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.1782 177.488C39.1782 177.086 39.5043 176.76 39.9066 176.76H42.1228C42.5251 176.76 42.8512 177.086 42.8512 177.488V185.316C42.8512 185.719 42.5251 186.045 42.1228 186.045H39.9066C39.5043 186.045 39.1782 185.719 39.1782 185.316V177.488ZM45.5387 177.249C45.1364 177.249 44.8102 177.575 44.8102 177.977V179.362C42.8515 178.707 42.8515 177.371 42.8515 177.371V185.312C42.8515 185.312 43.0038 184.096 44.8102 183.582V184.583C44.8102 184.986 45.1364 185.312 45.5387 185.312H53.6316C54.0339 185.312 54.36 184.986 54.36 184.583V181.992C54.4389 181.756 54.5526 181.547 54.7274 181.399C56.1761 180.179 59.7549 182.597 61.9554 184.147C62.1475 184.282 62.3943 184.093 62.3104 183.874C60.3504 178.751 57.1792 177.423 53.8962 177.298C53.8142 177.266 53.725 177.249 53.6316 177.249H45.5387Z"
            fill="#6C6B67"
        />
        <path
            d="M36.3946 12.355C36.7851 11.3558 37.9116 10.8623 38.9108 11.2527L79.6179 27.1587C80.6171 27.5491 81.1106 28.6756 80.7202 29.6748L60.2192 82.1418C59.8288 83.141 58.7023 83.6345 57.7031 83.2441L16.9959 67.3381C15.9967 66.9477 15.5032 65.8212 15.8936 64.822L36.3946 12.355Z"
            fill="#025A65"
        />
        <rect
            x="39.3062"
            y="15.5781"
            width="39.8195"
            height="52.4452"
            rx="0.971208"
            transform="rotate(21.3426 39.3062 15.5781)"
            fill="#FCF5E6"
        />
        <mask id="mask4_532_957" mask-type="alpha" maskUnits="userSpaceOnUse" x="20" y="15" width="57" height="64">
            <rect
                x="39.4824"
                y="15.5393"
                width="39.8195"
                height="52.4452"
                rx="0.971208"
                transform="rotate(21.3426 39.4824 15.5393)"
                fill="#FCF5E6"
            />
        </mask>
        <g mask="url(#mask4_532_957)">
            <path
                d="M19.6672 58.0297L63.7962 62.7403L56.7269 80.8324L18.9388 65.7993L19.6672 58.0297Z"
                fill="#F5D79D"
            />
            <path
                d="M46.6178 54.6902C47.2098 55.4426 48.6853 56.8382 49.8512 56.4018C51.3087 55.8563 50.0387 53.1089 52.3528 52.0392C54.6669 50.9694 55.4857 53.7972 56.9949 53.0996C58.5041 52.4019 56.6792 50.0392 58.3897 49.2486C60.1001 48.4579 61.5728 50.9834 63.7361 49.9834C65.8993 48.9834 64.4666 47.3372 65.1309 46.1324"
                stroke="#F5D79D"
                strokeWidth="0.971208"
            />
            <rect x="26.2227" y="4.85602" width="47.1036" height="23.309" fill="#FBEED5" />
            <circle cx="49.4364" cy="34.8682" r="8.03114" transform="rotate(21.3426 49.4364 34.8682)" fill="#F9E394" />
        </g>
        <mask id="mask5_532_957" mask-type="alpha" maskUnits="userSpaceOnUse" x="20" y="15" width="57" height="64">
            <rect
                x="39.4824"
                y="15.5393"
                width="39.8195"
                height="52.4452"
                rx="0.971208"
                transform="rotate(21.3426 39.4824 15.5393)"
                fill="#FCF5E6"
            />
        </mask>
        <g mask="url(#mask5_532_957)">
            <rect x="20.3955" y="17.9673" width="27.6794" height="50.9884" fill="#F8E3B9" />
            <path
                d="M19.6672 58.0297L63.7962 62.7403L56.7269 80.8324L18.9388 65.7993L19.6672 58.0297Z"
                fill="#F5D79D"
            />
            <path
                d="M46.6178 54.6902C47.2098 55.4426 48.6853 56.8382 49.8512 56.4018C51.3087 55.8563 50.0387 53.1089 52.3528 52.0392C54.6669 50.9694 55.4857 53.7972 56.9949 53.0996C58.5041 52.4019 56.6792 50.0392 58.3897 49.2486C60.1001 48.4579 61.5728 50.9834 63.7361 49.9834C65.8993 48.9834 64.4666 47.3372 65.1309 46.1324"
                stroke="#F5D79D"
                strokeWidth="0.971208"
            />
            <path
                d="M37.4336 37.7772C36.4762 37.778 34.4673 38.076 34.0901 39.2625C33.6186 40.7455 36.5636 41.4442 35.9752 43.9247C35.3867 46.4053 32.6573 45.3021 32.2735 46.9199C31.8897 48.5376 34.875 48.5623 34.44 50.3958C34.005 52.2293 31.1093 51.8271 30.5592 54.1459C30.0091 56.4647 32.1887 56.3552 32.7257 57.6218"
                stroke="#FEFCF7"
                strokeWidth="0.971208"
            />
            <rect x="26.2227" y="4.85602" width="47.1036" height="23.309" fill="#FBEED5" />
            <circle cx="49.4364" cy="34.8683" r="8.03113" transform="rotate(21.3426 49.4364 34.8683)" fill="#F5D79D" />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.219 15.661L123.156 15.2474L143.062 236.99C143.247 239.043 141.673 240.832 139.613 240.912L139.219 240.927C137.285 241.002 135.638 239.536 135.488 237.607L118.219 15.661ZM154.656 16.6807L159.565 16.0157L190.849 237.028C191.136 239.052 189.683 240.908 187.649 241.117L187.247 241.158C185.305 241.357 183.558 239.972 183.307 238.036L181.169 221.515H160.093V218.363H180.761L176.972 189.091H151.536V185.939H176.564L172.775 156.668H138.477V152.615H172.251L168.52 123.794H133.523V119.741H167.996L164.265 90.9195H131.458V86.8665H163.741L154.656 16.6807Z"
            fill="#C4C4C4"
            fillOpacity="0.4"
        />
        <path
            d="M118.376 2.8982C118.434 1.52853 119.586 0.462207 120.956 0.510051V0.510051C122.326 0.557895 123.401 1.70199 123.363 3.07238L116.943 237.251C116.888 239.266 115.19 240.843 113.176 240.751L111.879 240.691C109.896 240.6 108.361 238.923 108.445 236.94L118.376 2.8982Z"
            fill="#AB8627"
        />
        <path
            d="M153.854 2.71165C153.816 1.34126 154.891 0.197155 156.261 0.149312V0.149312C157.631 0.101468 158.783 1.16779 158.841 2.53746L168.786 236.98C168.87 238.963 167.335 240.641 165.352 240.732L164.055 240.791C162.041 240.884 160.344 239.306 160.288 237.291L153.854 2.71165Z"
            fill="#AB8627"
        />
        <rect x="120.014" y="20.668" width="36.4767" height="4.95363" fill="#AB8627" />
        <rect x="120.014" y="53.5421" width="36.4767" height="4.95363" fill="#AB8627" />
        <rect x="120.014" y="86.4162" width="36.4767" height="4.95363" fill="#AB8627" />
        <rect x="118.212" y="119.29" width="40.0793" height="4.95363" fill="#AB8627" />
        <rect x="118.663" y="152.164" width="39.1787" height="4.95363" fill="#AB8627" />
        <rect x="116.411" y="185.038" width="43.682" height="4.95363" fill="#AB8627" />
        <rect x="116.411" y="217.912" width="43.682" height="4.95363" fill="#AB8627" />
        <rect x="120.014" y="20.668" width="36.4767" height="4.95363" fill="#AB8627" />
        <rect x="120.014" y="53.5421" width="36.4767" height="4.95363" fill="#AB8627" />
        <rect x="120.014" y="86.4162" width="36.4767" height="4.95363" fill="#AB8627" />
        <rect x="118.212" y="119.29" width="40.0793" height="4.95363" fill="#AB8627" />
        <rect x="118.663" y="152.164" width="39.1787" height="4.95363" fill="#AB8627" />
        <rect x="116.411" y="185.038" width="43.682" height="4.95363" fill="#AB8627" />
        <rect x="116.411" y="217.912" width="43.682" height="4.95363" fill="#AB8627" />
    </svg>
);
