import React from "react";

import { NavBar } from "@wayhome-uk/component-library-v2";
import { Button, FullNameLogo, GridContainer, GridItem } from "@wayhome-uk/design-system-v2";
import { useAuthUser } from "@wayhome-uk/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import styled from "styled-components";

import { menuData } from "./menu-data";

export const Navigation = ({ isMarketingPage }: { isMarketingPage: boolean }) => {
    const { user, signOut, isAuthenticated } = useAuthUser();
    const { applicationsMigration } = useFlags();

    // @ts-expect-error: Property 'waitListStatus' does not exist on type 'IApplicationStatus'.ts(2339)
    const isFirstFund = applicationsMigration ? false : user.application?.waitListStatus === "first_fund";
    const applicationId = (applicationsMigration ? user.applicationV3?.id : user.uuid) as string;

    const handleLogout = async () => {
        await signOut();
    };

    const menuOptions = menuData(handleLogout, applicationId, isFirstFund);

    if (isMarketingPage) {
        return (
            <Header data-testid="navbar-campaign" role="banner">
                <GridContainer>
                    <StyledGridItem $colSpan={{ sm: 1, md: 12 }}>
                        <FullNameLogo />
                        <Button variant="text-button" as="a" href="https://www.wayhome.co.uk/eligibility">
                            Eligibility criteria
                        </Button>
                    </StyledGridItem>
                </GridContainer>
            </Header>
        );
    }

    return <NavBar isLoggedIn={isAuthenticated} menu={menuOptions} />;
};

const Header = styled.header`
    background-color: var(--white);
`;

const StyledGridItem = styled(GridItem)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-16) 0;
`;
