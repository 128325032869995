import React from "react";

import Head from "next/head";

interface IMetaTagsProps {
    title: string;
    description?: string;
    isBlog?: boolean;
}

export const MetaTags = ({ title, description, isBlog = false }: IMetaTagsProps) => (
    <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <title>{isBlog ? title : `${title} | Wayhome`}</title>
        {description && <meta name="description" content={description} />}

        <link rel="icon" href="/favicon.ico" />
    </Head>
);
