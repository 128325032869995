/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType } from "react";

import { getGlobalWindow, loadIntercomWidget } from "./load-intercom-widget";

export const withIntercom = (WrappedComponent: ComponentType<any>) => {
    const globalWindow = getGlobalWindow();

    if (globalWindow && !globalWindow.Intercom) {
        loadIntercomWidget();
    }

    // eslint-disable-next-line react/display-name
    return (props: any) => {
        return <WrappedComponent {...props} />;
    };
};
