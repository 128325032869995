import React from "react";

import { Footer } from "@wayhome-uk/component-library-v2";

import { Navigation } from "components";

interface ILayoutProps {
    children: React.ReactNode;
    pageType?: string;
}

export const Layout = ({ children, pageType }: ILayoutProps) => {
    const isMarketingPage = pageType === "marketing";

    return (
        <>
            <Navigation isMarketingPage={isMarketingPage} />
            {children}
            <Footer showFaqIntercomLink={!isMarketingPage} />
        </>
    );
};
