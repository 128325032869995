import React from "react";

import { Body, Link } from "@wayhome-uk/design-system-v2";
import styled from "styled-components";

interface IVideoProps {
    videoUrl: string;
    title?: string;
    isBlog?: boolean;
}

export const VideoMedia = ({ videoUrl, title, isBlog = false }: IVideoProps) => {
    return (
        <StyledVideo controls aria-label={title} $isBlog={isBlog}>
            <source src={videoUrl} />
            <Body>
                Your browser doesn&apos;t support HTML video. Here is a
                <Link href={videoUrl} download={videoUrl}>
                    link to the video
                </Link>
                instead.
            </Body>
        </StyledVideo>
    );
};

const StyledVideo = styled.video<{ $isBlog: boolean }>`
    width: 100%;
    align-self: center;
    max-width: ${({ $isBlog }) => ($isBlog ? "80rem" : "60rem")};
    padding-block: ${({ $isBlog }) => ($isBlog ? "var(--spacing-16)" : "0")};
`;
