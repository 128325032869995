import React, { useEffect } from "react";

import Player from "@vimeo/player";
import styled from "styled-components";

export interface IExplainerVideoProps {
    className?: string;
}

export const ExplainerVideo = ({ className }: IExplainerVideoProps) => {
    useEffect(() => {
        const iframe = document.querySelector("iframe") || "";
        const player = new Player(iframe);
        const cuePointKey = { customKey: "half-of-video-viewed" };

        player.addCuePoint(47, cuePointKey);
    }, []);

    return (
        <Wrapper data-testid="explainer-video" className={className}>
            <StyledIframe
                src="https://player.vimeo.com/video/686185283?texttrack=en-GB&title=false&portrait=false&byline=false"
                title="wayhome-explainer-video"
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const StyledIframe = styled.iframe`
    width: 100%;
    aspect-ratio: 16/9;
    border: none;
`;
