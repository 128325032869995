import { getApps, initializeApp } from "firebase/app";
import { indexedDBLocalPersistence, initializeAuth } from "firebase/auth";

export const initFirebase = () => {
    const firebaseApps = getApps();

    if (firebaseApps.length === 0) {
        const app = initializeApp({
            apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
            authDomain: process.env.NEXT_PUBLIC_FIREBASE_DOMAIN,
        });

        const auth = initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
        auth.tenantId = `${process.env.NEXT_PUBLIC_FIREBASE_TENANT}`;
    }
};
