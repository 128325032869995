import React, { Suspense, lazy } from "react";

import { VisualEditing } from "@sanity/visual-editing/next-pages-router";
import "@wayhome-uk/design-system-v2/dist/styles/global.css";
import "@wayhome-uk/design-system-v2/dist/styles/theme.css";
import { AuthProvider, CookieFirstProvider } from "@wayhome-uk/hooks";
import { getLDSecondaryKey } from "@wayhome-uk/utils";
import { getAuth } from "firebase/auth";
import { useFlags, withLDProvider } from "launchdarkly-react-client-sdk";
import { NextComponentType } from "next";
import type { AppProps } from "next/app";

import { Intercom, Layout } from "components";
import { IPage } from "types/pages";

import { initFirebase } from "../firebase";

initFirebase();

const PreviewProvider = lazy(() => import("components/preview-provider"));

export interface SharedPageProps {
    draftMode: boolean;
    token: string;
    page: IPage;
}

function MyApp({ Component, pageProps }: AppProps<SharedPageProps>) {
    const { draftMode, token, page } = pageProps;
    const { applicationsMigration } = useFlags();

    const pageType = page?._type;

    return draftMode ? (
        <PreviewProvider token={token}>
            <AuthProvider
                auth={getAuth()}
                apiBaseURL={process.env.NEXT_PUBLIC_API_BASE_URL || ""}
                applicationsMigrationFlag={applicationsMigration}
            >
                <CookieFirstProvider
                    domain="wayhome.co.uk"
                    apiKey={process.env.NEXT_PUBLIC_COOKIES_FIRST_API_KEY || ""}
                >
                    <Layout pageType={pageType}>
                        <Component {...pageProps} />
                    </Layout>
                </CookieFirstProvider>
            </AuthProvider>

            <Suspense>
                <VisualEditing />
            </Suspense>
        </PreviewProvider>
    ) : (
        <AuthProvider
            auth={getAuth()}
            apiBaseURL={process.env.NEXT_PUBLIC_API_BASE_URL || ""}
            applicationsMigrationFlag={applicationsMigration}
        >
            <Intercom />
            <CookieFirstProvider domain="wayhome.co.uk" apiKey={process.env.NEXT_PUBLIC_COOKIES_FIRST_API_KEY || ""}>
                <Layout pageType={pageType}>
                    <Component {...pageProps} />
                </Layout>
            </CookieFirstProvider>
        </AuthProvider>
    );
}

const Page = withLDProvider({
    clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID as string,
    user: {
        key: "anonymous",
        secondary: getLDSecondaryKey(),
    },
    options: {
        bootstrap: "localStorage",
    },
})(MyApp as NextComponentType<AppProps>);

export default Page;
